$fontsRoot: "../fonts/";

@font-face {
    font-family: 'Rubik';
    src: url($fontsRoot + 'Rubik/Rubik-BlackItalic.woff2') format('woff2'),
        url($fontsRoot + 'Rubik/Rubik-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url($fontsRoot + 'Rubik/Rubik-SemiBoldItalic.woff2') format('woff2'),
        url($fontsRoot + 'Rubik/Rubik-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url($fontsRoot + 'Rubik/Rubik-LightItalic.woff2') format('woff2'),
        url($fontsRoot + 'Rubik/Rubik-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url($fontsRoot + 'Rubik/Rubik-BoldItalic.woff2') format('woff2'),
        url($fontsRoot + 'Rubik/Rubik-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url($fontsRoot + 'Rubik/Rubik-Bold.woff2') format('woff2'),
        url($fontsRoot + 'Rubik/Rubik-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url($fontsRoot + 'Rubik/Rubik-ExtraBoldItalic.woff2') format('woff2'),
        url($fontsRoot + 'Rubik/Rubik-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url($fontsRoot + 'Rubik/Rubik-MediumItalic.woff2') format('woff2'),
        url($fontsRoot + 'Rubik/Rubik-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url($fontsRoot + 'Rubik/Rubik-Italic.woff2') format('woff2'),
        url($fontsRoot + 'Rubik/Rubik-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url($fontsRoot + 'Rubik/Rubik-Black.woff2') format('woff2'),
        url($fontsRoot + 'Rubik/Rubik-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url($fontsRoot + 'Rubik/Rubik-SemiBold.woff2') format('woff2'),
        url($fontsRoot + 'Rubik/Rubik-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url($fontsRoot + 'Rubik/Rubik-ExtraBold.woff2') format('woff2'),
        url($fontsRoot + 'Rubik/Rubik-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url($fontsRoot + 'Rubik/Rubik-Light.woff2') format('woff2'),
        url($fontsRoot + 'Rubik/Rubik-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url($fontsRoot + 'Rubik/Rubik-Medium.woff2') format('woff2'),
        url($fontsRoot + 'Rubik/Rubik-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url($fontsRoot + 'Rubik/Rubik-Regular.woff2') format('woff2'),
        url($fontsRoot + 'Rubik/Rubik-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/***** Karia google font *****/

@font-face {
    font-family: 'Karla';
    src: url($fontsRoot + 'Karia/Karla-BoldItalic.woff2') format('woff2'),
        url($fontsRoot + 'Karia/Karla-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Karla';
    src: url($fontsRoot + 'Karia/Karla-ExtraBoldItalic.woff2') format('woff2'),
        url($fontsRoot + 'Karia/Karla-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Karla';
    src: url($fontsRoot + 'Karia/Karla-Italic.woff2') format('woff2'),
        url($fontsRoot + 'Karia/Karla-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Karla';
    src: url($fontsRoot + 'Karia/Karla-MediumItalic.woff2') format('woff2'),
        url($fontsRoot + 'Karia/Karla-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Karla';
    src: url($fontsRoot + 'Karia/Karla-SemiBoldItalic.woff2') format('woff2'),
        url($fontsRoot + 'Karia/Karla-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Karla';
    src: url($fontsRoot + 'Karia/Karla-ExtraBold.woff2') format('woff2'),
        url($fontsRoot + 'Karia/Karla-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Karla';
    src: url($fontsRoot + 'Karia/Karla-SemiBold.woff2') format('woff2'),
        url($fontsRoot + 'Karia/Karla-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Karla';
    src: url($fontsRoot + 'Karia/Karla-Regular.woff2') format('woff2'),
        url($fontsRoot + 'Karia/Karla-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Karla';
    src: url($fontsRoot + 'Karia/Karla-Medium.woff2') format('woff2'),
        url($fontsRoot + 'Karia/Karla-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Karla';
    src: url($fontsRoot + 'Karia/Karla-Bold.woff2') format('woff2'),
        url($fontsRoot + 'Karia/Karla-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Karla';
    src: url($fontsRoot + 'Karia/Karla-LightItalic.woff2') format('woff2'),
        url($fontsRoot + 'Karia/Karla-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Karla';
    src: url($fontsRoot + 'Karia/Karla-ExtraLight.woff2') format('woff2'),
        url($fontsRoot + 'Karia/Karla-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Karla';
    src: url($fontsRoot + 'Karia/Karla-ExtraLightItalic.woff2') format('woff2'),
        url($fontsRoot + 'Karia/Karla-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Karla';
    src: url($fontsRoot + 'Karia/Karla-Light.woff2') format('woff2'),
        url($fontsRoot + 'Karia/Karla-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

